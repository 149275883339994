import { Box, useTheme } from "@mui/material"


const OcrTextView = ({ ocrValue }) => {

    const theme = useTheme();

    return (
        <Box>
            <textarea   aria-label="ocr values"
                        placeholder="OCR values..."
                        defaultValue={ocrValue}
                        disabled={true}    
                        style={{ 
                            width: '95%', 
                            height: '82vh', 
                            overflow: 'auto', 
                            resize: 'none',
                            border: '0px solid',
                            // backgroundColor: theme.palette.background.paper,
                            color: theme.palette.text.primary
                        }}
            ></textarea>
        </Box>
    )
}

export default OcrTextView;
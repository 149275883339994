import { Box, Button, Dialog, DialogTitle, Modal, Tab, Tabs, Typography, useTheme } from '@mui/material';
import React, {useState, useEffect, useMemo} from 'react';
import { validationIssueModalStyle } from "../../styles.js";
import { AgGridReact } from "@ag-grid-community/react";
import Tooltip from '@mui/material/Tooltip';
import gtConfig from '../../config.js';
import { getPathfinderJobs, unlockSelectedDocuments } from '../../services.jsx';
import { useOktaAuth } from '@okta/okta-react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock, faLockOpen, faWindowClose} from "@fortawesome/free-solid-svg-icons";
import UnlockDocumentsConfirm from './UnlockDocumentsConfirm';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import LinearProgress from '@mui/material/LinearProgress';
import { useThemeContext } from '../../theme/ThemeContextProvider.jsx';


export const tableHeaderTitle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 10px',
  height: '40px'
};

export const tableHeaderTitleTypographyStyle= {
  variant: 'h4',
  fontSize: {
      xs: '0.75rem', // smaller font size on extra-small devices
      sm: '0.875rem', // slightly larger font size on small devices
      md: '1.0rem', // default font size on medium devices and up
  },
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};

function DocumentLockTabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
      <Box sx={{height: "calc(100% - 40px)"}}
           role="tabpanel"
           hidden={value !== index}
           id={`simple-tabpanel-${index}`}
           aria-labelledby={`simple-tab-${index}`}
           {...other}
      >
          {value === index && <Box sx={{height: "inherit"}}>{children}</Box>}
      </Box>
  );
}

const LockDocumentsModal = ({ isOpen, onClose} ) => {
  
  const { authState, oktaAuth } = useOktaAuth();
  const [loading, setLoading] = useState(true);
  const [lockedDocuments, setLockedDocuments] = useState([]);
  const [unlockedDocuments, setUnlockedDocuments] = useState([]);
  const [selectedLockedDocuments, setSelectedLockedDocuments] = useState([]);
  const [selectedUnlockedDocuments, setSelectedUnlockedDocuments] = useState([]);
  const [isUnlockModalOpen, setIsUnlockModalOpen] = useState(false);
  const [isLockModalOpen, setIsLockModalOpen] = useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState('');

  const theme = useTheme();
  const { themeMode, toggleColorMode } = useThemeContext();
  const [aggridTheme, setAggridTheme] = useState(null);

  useEffect(() => {
    setAggridTheme(themeMode === 'light' ? 'ag-theme-quartz ag-theme-sp-light-default' : 'ag-theme-quartz-dark ag-theme-sp-dark-default')
  }, [aggridTheme, themeMode]);


  const showUnlockDocumentButton = () => {
    const email = authState.idToken.claims.email;
    const userId = email.replace('@spglobal.com', '');
  
    if (process.env.REACT_APP_ENV === 'development'){
       return true;
    }
  
    if (process.env.REACT_APP_ENV === 'qa' && gtConfig.qa_unlock_authorized_users.includes(userId)) {
     return true;
    }
  
    if (process.env.REACT_APP_ENV === 'production' && gtConfig.prod_unlock_authorized_users.includes(userId)) {
      return true;
    }
    
    return false;
  }
  

  const [LockColDefs,] = useState([
    {
      headerCheckboxSelection: false,
      checkboxSelection: true,
      width: 50,
      suppressSizeToFit: true,
      sortable: false,
      filter: false,
      resizable: false,
      hide: !showUnlockDocumentButton()
     },
     {
        field: "attributes.filename",
        headerName: "Document",
        width: 400,
        editable: false,
        sortable: true,
        filter: "agSetColumnFilter",
        cellStyle: { textAlign: "left" },
    },
    {
      field: "attributes.filetype",
      headerName: "Type",
      width: 200,
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      cellStyle: { textAlign: "left" },
    },
    {
      field: "username",
      headerName: "Locked By",
      width: 350,
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      cellStyle: { textAlign: "left" },
    },
    {
      field: "taskOwner",
      headerName: "Task Owner",
      hide: true,
      width: 350,
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      cellStyle: { textAlign: "left" },
    },
    {
      field: "streamName",
      headerName: "Stream Name",
      width: 350,
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      cellStyle: { textAlign: "left" },
  },
]);

const [unLockColDefs,] = useState([
   {
      field: "attributes.filename",
      headerName: "Document",
      width: 400,
      editable: false,
      cellStyle: { textAlign: "left" },
      sortable: true,
      filter: "agSetColumnFilter",
      // cellRenderer: (params) => {
      //   return (<Button variant="text" style={{ cursor: 'pointer' }}  onClick={() => handleClickDocument(params)}> {params.value} </Button>
      //   );
      // }
  },
  {
    field: "attributes.filetype",
    headerName: "Type",
    width: 200,
    editable: false,
    cellStyle: { textAlign: "left" },
    sortable: true,
    filter: "agSetColumnFilter",
  },
  {
    field: "username",
    headerName: "Locked By",
    width: 350,
    editable: false,
    cellStyle: { textAlign: "left" },
    sortable: true,
    filter: "agSetColumnFilter",
  },
  {
    field: "taskOwner",
    headerName: "Task Owner",
    width: 350,
    hide: true,
    editable: false,
    cellStyle: { textAlign: "left" },
    sortable: true,
    filter: "agSetColumnFilter",
  },
  {
    field: "streamName",
    headerName: "Stream Name",
    width: 350,
    editable: false,
    cellStyle: { textAlign: "left" },
    sortable: true,
    filter: "agSetColumnFilter",
},
]);

const selectionUnlockDocs = useMemo(() => {
  return {
    mode: "multiRow",
    checkboxes: showUnlockDocumentButton(),
    headerCheckbox: false,
    enableClickSelection: true,
  };
}, []);

const selectionLockDocs = useMemo(() => {
  return {
    mode: "multiRow",
    checkboxes: showUnlockDocumentButton(),
    headerCheckbox: false,
    enableClickSelection: true,
  };
}, []);

function a11yProps(index) {
  return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
  };
}


// const handleClickDocument = (params) => {
//   setSelectedDocument(params.value,params.data.attributes.filetype);
//   onClose();
// };

const onLockedSelectionChanged = (event) => {
  const selectedNodes = event.api.getSelectedNodes();
  const docs = selectedNodes.map((node) =>  ({
    file_name: node.data.attributes.filename,
    username: node.data.username
  }));
  setSelectedLockedDocuments(docs);
}

const onUnlockedSelectionChanged = (event) => {
  const maxUnlockDocuments = gtConfig.pathfinder.maxUnlockDocuments;
  const selectedNodes = event.api.getSelectedNodes();
   if (selectedNodes.length > maxUnlockDocuments) {
    const lastSelectedNode = event.api.getSelectedNodes().slice(-1)[0];
    if (lastSelectedNode) {
      lastSelectedNode.setSelected(false);
    }
    showSnackBar(`You could only select up to ${maxUnlockDocuments} items.`, 'warning');
    return;
  }

  const docs = selectedNodes.map((node) =>  ({
    file_name: node.data.attributes.filename,
    username: node.data.username
  }));
  setSelectedUnlockedDocuments(docs);
};


const handleTabChange = (event, tabIndex) => {
  setSelectedLockedDocuments([]);
  setSelectedUnlockedDocuments([]);
  setTabIndex(tabIndex);
};

const fetchLockDocuments = () => {
  const fetchjobs = async () => {
    try {
      setLoading(true);
      const url = gtConfig.ground_truth_back_end.getJob + "?task_statues=[2]";
      const result =  await getPathfinderJobs(authState, oktaAuth, url);
      if (result && result.response && result.response.list);
      setLockedDocuments(result.response.list);
  
    } catch (error) {
      console.error('Error fetching Lock Documents:', error);
    } finally {
      setLoading(false);
    }
  }
  fetchjobs();
}

const fetchUnlockDocuments = () => {
  setUnlockedDocuments([]);
  // const fetchjobs = async () => {
  //   try {
  //     setLoading(true);
  //     const url = gtConfig.ground_truth_back_end.getJob + "?task_statues=[1,2,3]";
  //     const result =  await getPathfinderJobs(authState, oktaAuth, url);
  //     if (result && result.response && result.response.list)
  //     setUnlockedDocuments(result.response.list);
  
  //   } catch (error) {
  //     console.error('Error fetching Unlock documents:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // }
  // fetchjobs();
}

const defaultColDef = {
  sortable: true,
  filter: "agSetColumnFilter",
  resizable: true,
  flex: 1,
};

const onSaveUnlockDocuments = async () => {
  try {
    setIsUnlockModalOpen(false)
      setLoading(true);
      const url = gtConfig.ground_truth_back_end.unlockDocumentsUrl + '?is_validation=false';
      const response = await unlockSelectedDocuments(authState, oktaAuth, url, selectedUnlockedDocuments);
      if (response.success) {
        showSnackBar('Unlock process finished successfully.', 'success');
        //fetchLockDocuments();
        setTimeout(() => onClose(true), 1000);
      } else {
        showSnackBar(response.message, 'error');
      }
  } catch (error) {
      showSnackBar(error.message, 'error');
      console.error('Failed to unlock documents:', error);
  } finally{
      setSelectedLockedDocuments([]);  
      setIsUnlockModalOpen(false);
      setLoading(false);
  } 
};
    
  
useEffect(() => {
  fetchLockDocuments();
  fetchUnlockDocuments();
}, []);

function showSnackBar(msg, severity){
  setSnackBarMsg(msg);
  setSnackBarSeverity(severity);
  handleOpenSnackBar();
}

const handleCloseSnackBar = () => {
  setOpenSnackBar(false);
};

const handleOpenSnackBar = () => {
  setOpenSnackBar(true);
};
 
  return (
    <>
      <Modal 
          open={isOpen} 
          onClose={onClose}
          aria-labelledby="Validation Issues"
          aria-describedby="Validation Issues"
          >
          <Box sx={{...validationIssueModalStyle, backgroundColor: theme.palette.background.paper}}>
              <Snackbar
                    open={openSnackBar}
                    onClose={handleCloseSnackBar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Top center position
                    autoHideDuration={3000} // Auto hide after 3 seconds
                >
                    <Alert
                        onClose={handleCloseSnackBar}
                        message="Snackbar from right div"
                        severity={snackBarSeverity}
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {snackBarMsg}
                    </Alert>
              </Snackbar>
              <div style={{margin:0, padding:0, height: 4}}>
                { loading && <LinearProgress color="primary" title='Uploading pdf document'/>}
              </div>
                <Tabs value={tabIndex} onChange={handleTabChange} centered>
                  <Tab icon={<FontAwesomeIcon icon={faLockOpen}/>} label="Unlock Documents" {...a11yProps(0)}/>
                  {/* <Tab disabled={true} icon={<FontAwesomeIcon icon={faLock}/>} label="Lock Documents"  {...a11yProps(1)}/> */}
                  {/* <Tab icon={<FontAwesomeIcon icon={faWindowClose} size='2x' onClick={onClose}/>}  {...a11yProps(2)}/> */}
                </Tabs>
                <DocumentLockTabPanel value={tabIndex} index={0}>
                <Dialog
                      open={isUnlockModalOpen}
                      onClose={() => setIsUnlockModalOpen(false)}
                      aria-labelledby="unlock-documents-confirm-dialog"
                      aria-describedby="unlock-documents-confirm-dialog"
                  >
                      <DialogTitle>
                          Unlock
                      </DialogTitle>
                      {selectedUnlockedDocuments &&
                          <UnlockDocumentsConfirm selectedDocuments={selectedUnlockedDocuments}
                                                onSave={(userId) => onSaveUnlockDocuments(userId)}
                                                onCancel={() => setIsUnlockModalOpen(false)}/>}
                  </Dialog>
                  <Box sx={{
                      // backgroundColor: theme.palette.background.paper,
                      height: '100%',
                      width: '100%',
                  }}>
                      <Box sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          // borderRadius: "5px",
                          // border: "1px solid",
                          // borderColor: theme.palette.divider,
                          // backgroundColor: theme.palette.background.paper,
                          flexDirection: "column"
                      }}>
                          <Box sx={{...tableHeaderTitle, width: '100%'}}>
                              <Typography sx={{...tableHeaderTitleTypographyStyle, textAlign: 'left'}}>Unlock
                                  Documents</Typography>
                              { showUnlockDocumentButton() && <Tooltip title='Unlock Documents'>
                              <span>
                                  <Button color="primary"
                                          variant="contained"
                                          disableElevation
                                          sx={{height: '30px', marginRight: '12px'}}
                                          startIcon={<FontAwesomeIcon icon={faLockOpen} size="xs"/>}
                                          disabled={selectedUnlockedDocuments.length === 0}
                                          onClick={() => setIsUnlockModalOpen(true)}
                                  >
                                     { `Unlock ${selectedUnlockedDocuments.length > 0 ? '(' + selectedUnlockedDocuments.length + ')' : ''} Document${selectedUnlockedDocuments.length > 1 ? 's' : ''}`}
                                  </Button>
                                  </span>
                              </Tooltip>}
                          </Box>
                          <Box sx={{height:'100%', width:'100%'}}>
                            <AgGridReact
                              rowData={lockedDocuments}
                              columnDefs={LockColDefs}
                              rowSelection={'multiple'}
                              className={aggridTheme}
                              selection={selectionUnlockDocs}
                              loading={loading}
                              onSelectionChanged={onUnlockedSelectionChanged}
                          />
                          </Box>
                      </Box>
                  </Box>
                </DocumentLockTabPanel>
                <DocumentLockTabPanel value={tabIndex} index={1}>
                  <Box sx={{
                      // backgroundColor: theme.palette.background.paper,
                      height: '100%',
                      width: '100%',
                  }}>
                      <Box sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          borderRadius: "5px",
                          border: "1px solid",
                          // borderColor: theme.palette.divider,
                          // backgroundColor: theme.palette.background.paper,
                          flexDirection: "column"
                      }}>
                          <Box sx={{...tableHeaderTitle, width: '100%'}}>
                              <Typography sx={{...tableHeaderTitleTypographyStyle, textAlign: 'left'}}>Lock
                                  Documents</Typography>
                                  { showUnlockDocumentButton() && <Tooltip title='Unlock Documents'>
                                  <Button color="primary"
                                          variant="contained"
                                          disableElevation
                                          sx={{height: '30px'}}
                                          startIcon={<FontAwesomeIcon icon={faLockOpen} size="xs"/>}
                                          disabled={selectedLockedDocuments.length === 0}
                                          onClick={() => setIsLockModalOpen(true)}
                                  >
                                      {`Lock ${selectedLockedDocuments.length > 0 ? '(' + selectedLockedDocuments.length + ')' : ''} Document${selectedLockedDocuments.length > 1 ? 's' : ''}`}
                                  </Button>
                              </Tooltip> }
                          </Box>
                          <Box sx={{height:'100%', width:'90%'}}>
                            <AgGridReact
                              rowData={unlockedDocuments}
                              columnDefs={unLockColDefs}
                              defaultColDef={defaultColDef}
                              rowSelection={'multiple'}
                              className={aggridTheme}
                              selection={selectionLockDocs}
                              loading={loading}
                              // onSelectionChanged={onLockedSelectionChanged}
                          />
                          </Box>             
                      </Box>
                  </Box>
                </DocumentLockTabPanel>
          </Box>                
      </Modal>
    </>
  );
};

export default LockDocumentsModal;

import React, {useState, useEffect} from 'react';
import gtConfig from "../../config";
import 'react-datepicker/dist/react-datepicker.css';
import InputBoxMenuContext from './../../share/InputBoxMenuContext';
import SelectComponent from './SelectComponent';
import { removeIndexFromLabel } from '../../Utils';
import { addAttributeKeyToChangedAttributeRef } from './ChangedAttribute';
import CustomeDateInputBox from '../../share/CustomeDateInputBox';
import { Button, useTheme } from '@mui/material';

const MyGrid = ({
                    initialData,
                    id,
                    extractionKey,
                    handleMouseOverEvent,
                    handleMouseOutEvent,
                    handleClickEvent,
                    handleConfirmTableRow,
                    handleDeleteTableRow,
                    mainDivClass,
                    handleKeySelect,
                    handleAddNewRowToTable,
                    isConfirmed,
                    editMode,
                    fileType,
                    handleClearGridInputBox,
                    updateGridValues,
                    changedAttributesRef
                }) => {
    // const [updatedData, setUpdatedData] = useState(data);
    const [data, setData] = useState(initialData);
    const schedulePreDefinedTypes = gtConfig.schedule_pre_defined_types[fileType];
    const scheduleSize_setting = gtConfig.schedule_size_large;
    const scheduleName = extractionKey.substring(0, extractionKey.indexOf('['));
    const typeMap = schedulePreDefinedTypes[scheduleName];

    const theme = useTheme();

    useEffect(() => {
         if (Array.isArray(initialData) && initialData.length >= 2 && Array.isArray(initialData[0])) {
            // Check if first array (initialData[0]) contains objects with 'headers' and 'rows' properties
            const firstArray = initialData[0];
            const hasHeadersAndRows = firstArray.includes('headers') && firstArray.includes('rows');

            if (hasHeadersAndRows) {
                let combinedArray = [];
                const valuesWithEmptyStart = [[...Object.values(initialData[1][0][0])]];
                const transformedArray = Object.entries(initialData[1][1][0]).map(([key, values]) => [
                key, ...values ]);
                combinedArray = [
                ...valuesWithEmptyStart,
                ...transformedArray
                ];
                setData(combinedArray);
            } else {
               setData(initialData);
            }
        }

    }, [initialData]);

    function getFieldName(cell){
        return cell[1].substring(0, cell[1].indexOf('['));
    }

    // Empty onChange handler to prevent warning since we are using input inside form
    const handleInputChange = () => {
    };

    const handleSelectChange = (event, cellIndex, rowIndex, cell) => {
        const newValue = event.target.value.trim();
        changeCellValue(newValue, cellIndex, rowIndex, cell);
        
        addAttributeKeyToChangedAttributeRef(extractionKey, changedAttributesRef.current);
        //console.log("Grid. eventValue:", event.target.value, "cell Index=", cellIndex, " rowIndex", rowIndex, " data = ", data);
    };

    const handleClearInputValue = (cellIndex, rowIndex, cell) => {
        handleClearGridInputBox(extractionKey,cellIndex, rowIndex);
        changeCellValue('', cellIndex, rowIndex, cell);
    };

    const onMenuCopyToAllClick = (cellIndex, rowIndex, cell) => {
        const newData = structuredClone(data);
        const value = newData[rowIndex+1][cellIndex][0]
        for (let i = 1; i <  newData.length; i++){ // Starts from i = 1 to Skip the header
           newData[i][cellIndex][0] = value;
        }
        addAttributeKeyToChangedAttributeRef(extractionKey, changedAttributesRef.current);

        updateGridValues(newData);
        
      };
  
    const changeCellValue = (newValue, cellIndex, rowIndex, cell) => {
        const newData = structuredClone(data);
        const cellCopy = structuredClone(cell);
        cellCopy[0] = newValue;
        newData[rowIndex + 1][cellIndex] = cellCopy;
        //setData(newData);
        updateGridValues(newData);
    }


    return (
        <div key= {extractionKey} className="tableFixHead" style={{ maxHeight: scheduleSize_setting.includes(fileType) ? '600px' : '150px'}} >
            <table key={scheduleName} border="0" cellSpacing="0">
                <thead>
                    <tr key={extractionKey + id }>
                        <th>
                        <Button 
                                variant='outlined'
                                title='Add new row' 
                                type="button"
                                sx={{
                                    height: '5px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginLeft: 0,
                                    padding: '8px 22px',
                                    width: '23px',
                                    minWidth: 'auto'
                                }}
                                
                                disabled={!editMode || isConfirmed(extractionKey)}
                                onClick={() => handleAddNewRowToTable(extractionKey)}
                            >
                                +
                            </Button>
                        </th>
                        {data[0].map((header, index) => (
                            index !== data[0].length - 1 && 
                            <th key={header + index}>
                                <div>
                                    <div style={{color: theme.palette.text.secondary}}>{index === data[0].length - 1 ? null : header.replaceAll('_', ' ')}</div>
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody >
                    {data.slice(1).map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            <td>
                                <Button key={rowIndex + row[row.length - 1][0] + 'confirm'}
                                        variant='outlined'
                                        disabled={!editMode || isConfirmed(extractionKey)}
                                        onClick={() => handleConfirmTableRow(row[row.length - 1][0], row)}
                                        sx={{
                                            height: '20px',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: '4px 7px',
                                            width: '23px',
                                            minWidth: 'auto'
                                        }}
                                        title='Confirm if the row is not empty.'
                                >
                                    {isConfirmed(row[row.length - 1][0]) ?
                                     <label>↺</label>
                                      : <label>Y</label>}
                                </Button>

                                <Button 
                                        variant='outlined'
                                        sx={{
                                            height: '20px',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: '4px 7px',
                                            minWidth: 'auto'
                                        }}
                                        key={rowIndex + row[row.length - 1][0] + 'delete'}
                                        className={mainDivClass(row[row.length - 1][0])}
                                        disabled={!editMode || isConfirmed(extractionKey)}
                                        onClick={() => handleDeleteTableRow(row[row.length - 1], extractionKey, row)}>
                                    <label>X</label>
                                </Button>
                            </td>
                            {row.map((cell, cellIndex) => (
                                Array.isArray(cell) ?
                                    cellIndex !== data[0].length - 1 &&
                                    <td key={cellIndex + '-' + row[row.length - 1][0]} id={cell[1]}
                                        onMouseOver={() => handleMouseOverEvent(cell[1], row[row.length - 1][0], extractionKey)}
                                        onMouseOut={() => handleMouseOutEvent(cell[1], row[row.length - 1][0], extractionKey)}
                                        onClick={() => handleClickEvent(cell[1], row[row.length - 1][0], extractionKey)}>

                                        {
                                            isDateField(typeMap, cell)
                                                ? // Check if cell[1] starts with "date"
                                                <CustomeDateInputBox
                                                    cellIndex={cellIndex}
                                                    rowIndex={rowIndex}
                                                    cell={cell}
                                                    style={{
                                                        outline: 'none',
                                                        padding: '0px',
                                                        maxWidth:'94px',
                                                        backgroundColor: theme.palette.background.paper,
                                                        color: theme.palette.text.secondary,
                                                        borderRadius: 4,
                                                        border: `1px solid ${theme.palette.text.secondary}`,
                                                        // padding: '1px 2px 3px 3px',
                                                    }}
                                                    className={mainDivClass(row[row.length - 1][0])}
                                                    type='grid'
                                                    value={cell[0]}
                                                    onChange={(e) => handleSelectChange(e, cellIndex, rowIndex, cell)}
                                                    onMenuCopyToAllClick={onMenuCopyToAllClick}
                                                    disabled={ !editMode || isConfirmed(extractionKey)}
                                                />
                                                : Array.isArray(cell) && cell[1] && typeMap && Object.keys(typeMap).find(key => cell[1].startsWith(key))
                                                    ?
                                                    <SelectComponent 
                                                        key={extractionKey + cell[1] + 'select'}
                                                        extractionKey={extractionKey}
                                                        attribute={getFieldName(cell)}
                                                        mainDivClass={mainDivClass(row[row.length - 1][0])}
                                                        fileType={fileType}
                                                        initialData={cell[0]}
                                                        onSelectChange={(e) => handleSelectChange(e, cellIndex, rowIndex, cell)}
                                                        selectOptionStyle={{
                                                            outline: 'none', 
                                                            padding: '1px',  
                                                            maxWidth:'98px', 
                                                            minWidth:'98px',
                                                            borderRadius: 4,
                                                            border: `1px solid ${theme.palette.text.secondary}`,
                                                        }}
                                                        editMode={editMode}
                                                        isConfirmed={isConfirmed}
                                                        cellIndex={cellIndex}
                                                        rowIndex={rowIndex}
                                                        cell={cell}
                                                        type='grid'
                                                        disabled={!editMode}
                                                        onMenuCopyToAllClick={onMenuCopyToAllClick}
                                                    />
                                                    : // Default input if no conditions match
                                                    <div  style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <InputBoxMenuContext
                                                            key={extractionKey + cellIndex + cell[0] + 'input'}
                                                            inputBoxId={extractionKey + cellIndex}
                                                            inputBoxKey={[extractionKey, row[row.length - 1][0], cell[1]]}
                                                            inputBoxClassName={mainDivClass(row[row.length - 1][0])}
                                                            inputBoxType='text'
                                                            inputBoxValue={cell[0]}
                                                            inputBoxSize="9"
                                                            inputBoxStyle={{
                                                                outline: 'none', 
                                                                maxWidth: '95px',
                                                                backgroundColor: theme.palette.background.paper,
                                                                color: theme.palette.text.secondary,
                                                                borderRadius: 4,
                                                                border: `1px solid ${theme.palette.text.secondary}`,
                                                                padding: '1px 2px 3px 3px',
                                                            }}
                                                            mouseDownHandler={handleKeySelect}
                                                            changeHandler={handleInputChange}
                                                            onMenuClearClick={handleClearInputValue}
                                                            cellIndex={cellIndex}
                                                            rowIndex={rowIndex}
                                                            cell={cell}
                                                            type='grid'
                                                            disabled={!editMode}
                                                            onMenuCopyToAllClick={onMenuCopyToAllClick}

                                                        />
                                                    </div>
                                        }

                                    </td> :
                                    <td key={cellIndex}>
                                        <input className={mainDivClass(row[row.length - 1][0])} key={cellIndex}
                                            onChange={handleInputChange}
                                            style={{boarder: 'none', outline: 'none', padding: '1px', maxWidth:'95px'}} type="text" size="9" title={cell} disabled={!editMode}
                                            value={cell}/>
                                    </td>
                                                        
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default MyGrid;

function isDateField(typeMap, cell){
    const att = removeIndexFromLabel( cell[1])
    return Array.isArray(cell) && typeMap && cell[1] 
     && Object.keys(typeMap).find(key => cell[1].startsWith(key)) 
     && typeMap[att] === 'date';
}
  
import React from 'react';
import {Box, Button, ListItemText, Tooltip, useTheme} from '@mui/material';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";


const UnlockDocumentsConfirm = ({selectedDocuments, onSave, onCancel}) => {

    const theme = useTheme();

    return (
        <Box sx={{padding: '0 20px 10px 20px', backgroundColor: theme.palette.background.paper}}>
            <Box sx={{maxHeight: 200, overflowY: 'auto', mt: 1}}>
                <List>
                    {selectedDocuments.map((doc, index) => (
                        <ListItem key={index}>
                            <Tooltip title={doc.file_name}>
                                <ListItemText primary={doc.file_name} sx={{ maxWidth: '500px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}/>
                            </Tooltip>
                        </ListItem>
                    ))}
                </List>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 1}}>
                <Button variant="outlined" color="error" onClick={onCancel}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={onSave}>Confirm</Button>
            </Box>
        </Box>
    );
};

export default UnlockDocumentsConfirm;

import React, {useEffect, useState} from 'react';
import '../App.css';
import { useOktaAuth } from '@okta/okta-react';
import logo from '../aixtra.png';

const Login = () => {
    const [userInfo, setUserInfo] = useState(null);
    const { authState, oktaAuth } = useOktaAuth();

    useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
            setUserInfo(null);
        } else {
            oktaAuth.getUser().then((info) => {
                setUserInfo(info);
            });
        }
    }, [authState, oktaAuth]); // Update if authState changes

    const login = async () => {
        await oktaAuth.signInWithRedirect();
    };
    return(
        <div className="login-page" style={{ textAlign: 'center' }}>
            <header style={{ marginBottom: '20px', textAlign: 'center' }}>
                <img src={logo} alt="Logo" style={{ maxWidth: '100%', maxHeight: '100px' }} />
                <h2>
                    <p>Ground Truth Manager</p>
                </h2>
                <div>
                    <a href='#' id="login-button" primary onClick={login}>Login</a>
                </div>
            </header>
        </div>
    )
}

export default Login
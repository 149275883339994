export const getDesignTokens = (mode) => ({
    palette: {
        mode,
        ...(mode === 'light'
            ? {
                primary: { main: '#006d89' },
                secondary: { main: '#d6002a' },
                error: { main: '#8f001c' },
                warning: { main: '#c56c00' },
                info: { main: '#782080' },
                success: { main: '#009900' },
                background: {
                    default: '#f8f8f8',
                    paper: '#f1efefff',
                },
                text: {
                    primary: '#0d0d0d',
                    secondary: '#555555'
                },
                divider: '#babfc7'
            }
            : {
                primary: { main: '#299ebd' },
                secondary: { main: '#f51f49' },
                error: { main: '#ec8200' },
                warning: { main: '#db4d46' },
                info: { main: '#8b4092' },
                success: { main: '#009900' },
                background: {
                    default: '#030303',
                    paper: '#1c1c1cff',
                },
                text: {
                    primary: '#ffffff',
                    secondary: '#e0e0e0'
                },
                divider: '#68686e'
            }),
    },
});

import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Typography } from '@mui/material';
import {useTheme} from "@mui/material/styles";


export default function  LeftPanelOrderBy({navOrderBy, setNavOrderBy}){
  const theme = useTheme();

  return (
    <FormControl>
        <FormLabel id="order-by-radio-buttons-group-label"><Typography variant='caption' sx={{ color: theme.palette.text.primary}}>Order By</Typography></FormLabel>
        <RadioGroup
            row
            aria-labelledby="order-by-radio-buttons-group-label"
            name="order-by-radio-buttons-group"
            value={navOrderBy}
            onChange={(event) => setNavOrderBy(event.target.value)}
        > 
            {/* <Typography variant='caption'>Order By:</Typography> */}
            <FormControlLabel value="extraction" control={<Radio />} label={<Typography variant='caption'
                   sx={{ color: theme.palette.text.primary}}>Extraction</Typography>} />
            <FormControlLabel value="gt_ops_confirmed" control={<Radio />} label={<Typography variant='caption' 
                    sx={{ color: theme.palette.text.primary}}>Confirmed</Typography>} />
        </RadioGroup>
    </FormControl>
 
  );
};

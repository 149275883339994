// Update the ThemeContextProvider.tsx file

import React, {createContext, useContext, useState, useEffect} from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {getDesignTokens} from "./theme";

// Updated context type to include the theme object
// type ThemeContextType = {
//   mode: 'light' | 'dark';
//   toggleColorMode: () => void;
//   setColorMode: (mode: 'light' | 'dark') => void;
//   theme: ReturnType<typeof createTheme>;
// };

const ThemeContext = createContext(undefined);

export const ThemeContextProvider = ({ children }) => {
  
  const [themeMode, setMode] = useState( () =>
    {
      let currentTheme = localStorage.getItem('theme');
      if (currentTheme === 'light' || currentTheme === 'dark') {
        return currentTheme;
      } else {
        return 'light';
      }
    }
    
  );

  const updateCSSVariables = (themeMode) => {
    const root = document.documentElement;
    const lightVariables = {
      '--primary-50': '#d4e6eb',
      '--primary-100': '#aaced7',
      '--primary-200': '#7fb6c4',
      '--primary-300': '#559db0',
      '--primary-400': '#2a859c',
      '--primary': '#006d89',
      '--primary-600': '#005a72',
      '--primary-700': '#00485b',
      '--primary-800': '#003644',
      '--primary-900': '#00242d',
      '--primary-950': '#001216',
      '--secondary': '#d6002a',
      '--error': '#8f001c',
      '--warning': '#c56c00',
      '--info': '#782080',
      '--success': '#009900',
      '--bg-primary': '#f8f8f8',
      '--bg-secondary': '#f1efef',
      '--fg-primary': '#0d0d0d',
      '--fg-secondary': '#555555',
      '--divider': '#babfc7'
    };

    const darkVariables = {
      '--primary-50': "#dbeef4",
      '--primary-100': "#b7dee9",
      '--primary-200': "#94cede",
      '--primary-300': "#70bed3",
      '--primary-400': "#4caec8",
      '--primary': '#299ebd',
      '--primary-600': '#22839d',
      '--primary-700': '#1b697e',
      '--primary-800': '#144f5e',
      '--primary-900': '#0d343f',
      '--primary-950': '#061a1f',
      '--secondary': '#f51f49',
      '--error': '#ec8200',
      '--warning': '#db4d46',
      '--info': '#8b4092',
      '--success': '#009900',
      '--bg-primary': '#030303',
      '--bg-secondary': '#1c1c1c',
      '--fg-primary': '#ffffff',
      '--fg-secondary': '#e0e0e0',
      '--divider': '#68686e'
    };

    const variables = themeMode === 'light' ? lightVariables : darkVariables;

    Object.keys(variables).forEach((key) => {
      root.style.setProperty(key, variables[key]);
    });
  };

  const toggleColorMode = () => {
    setMode((prevMode) => {
      const newMode = prevMode === 'light' ? 'dark' : 'light';
      //localStorage.setItem('theme', newMode);
      updateCSSVariables(newMode);
      return newMode;
    });
  };

  const setColorMode = (themeMode) => {
    const mode = themeMode === 'light' ? 'light' : 'dark';
    setMode(mode);
   // localStorage.setItem('theme', mode);
    updateCSSVariables(themeMode);

  };


  useEffect(() => {
    updateCSSVariables(themeMode);
   // localStorage.setItem('theme', themeMode);
  }, [themeMode]);

  const theme = createTheme({
    ...getDesignTokens(themeMode),
    typography: {
      fontFamily: ['Akkurat', 'Roboto', 'sans-serif'].join(','),
    }
  });

  return (
      <ThemeContext.Provider value={{ themeMode, toggleColorMode, setColorMode, theme }}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useThemeContext must be used within a ThemeContextProvider');
  }
  return context;
};

import '../../App.css';
import React, {useEffect, useRef, useState} from 'react';
import pdfjs from 'pdfjs-dist';  // Don't remove this librray. It is needed to load pdf document
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {DIV_IDS} from '../../constants';
import gtConfig from './../../config.js';
import { loadingStyle } from "../../styles";
import { currentValueExistInContextValues, getContextValues, removeIndexFromLabel, clearDivMap, clearDivMapBaseOnCurrentPage, clearContextsBasedOnCurrentPage } from '../../Utils.js';
import { isTable } from '../RightPanel/TableAttributesUtil.js';
import { addAttributeKeyToChangedAttributeRef } from '../RightPanel/ChangedAttribute.js';
import { Box } from '@mui/material';

const MiddlePanel = ({selectedFile, jsonExtractionCoords, jsonTuples, selectedKey, extractedValues, onInputChange
                    ,selectingDivsRefs, removeHighlightColorFromSelectingDivs, 
                    handleClearInput, pdfDoc, isLoading, searchFeatureChecked, setTextDivs, setDivIdText
                    ,changedAttributesRef, fileType,middlePanelContentType, setMiddlePanelContentType,
                    triggerMiddlePanelContentType, canvasMapForOcrRefs, selectedTextRef, textDivRefs, fontValue}) => {

    if (selectedFile != null) {
        const hasPipe = selectedFile.includes('|');
        selectedFile = hasPipe ? selectedFile.split('|')[0].trim() : selectedFile.trim();
    }
    const [currentPdfFilePath, setCurrentPdfFilePath] = useState(null);
    const [selectedDivs, setSelectedDivs] = useState(new Set());     //Current divs for selected element
    const [selectedKeyValues, setSelectedKeyValues] = useState({})
    const [isMouseDown, setIsMouseDown] = useState(false);
    const abortControllerRef = useRef(new AbortController());


    console.log('MiddlePage rendering...');
    
    const [currentPage, setCurrentPage] = useState(1);

    // const divRefs = useRef([]);
    // const containerRef = useRef(null);
    // const textDivRefs = useRef(new Map());
    const redOcrDivRefs = useRef(new Map());
    const pdfRedOcrDivRefs = useRef(new Map());
    const noOcrDivRefs = useRef(new Map());

    const pdfContainerRef = useRef(null);

    const jsonTuplesPageRef = useRef(new Map());
    const jsonExtractionCoordsPageRef = useRef(new Map());

    const pdfPagesRef = useRef(new Map());
    const loadedPdfPagesRef = useRef(new Map());


    /*
        Reset selected Divs and selectedText when selectedKey
       changes to avoid adding the same text to the new selectedKey
    */
       useEffect(() => {
        textDivRefs.current = new Map();
        redOcrDivRefs.current = new Map();
        pdfRedOcrDivRefs.current = new Map();
        noOcrDivRefs.current = new Map();

        setMiddlePanelContentType('pdf'); // middlePage should show pdf when a new document is loaded.
        prepareJsonTuplesPageRef(jsonTuples);
        prepareJsonExtractionCoordsPageRef(jsonExtractionCoords);
    }, [selectedFile]);

       useEffect(() => {
        handleClearInput();
    }, [selectedKey]);


    useEffect(() => {
        if (canvasMapForOcrRefs.current && canvasMapForOcrRefs.current.size > 0){
            addDivTuplesToOCRPages();
        }
    }, [currentPage, triggerMiddlePanelContentType, middlePanelContentType ]);


    useEffect(() => {
        selectedTextRef.current = [];
        const handleScroll = () => {
            const middlePanelMain = document.getElementById('middle-panel-main');
            if (middlePanelMain) {
                const { scrollTop } = middlePanelMain;
                const totalPages = pdfDoc.numPages;
                
                /* canvasHeight is hardcoded from current canvas height values
                *  In future we should check if we could/need get this value dynamically.
                */
                const canvasHeight = 1346;
                const totalheight = totalPages * canvasHeight;
                const pageHeight = totalheight / totalPages;
                const currentPage = Math.floor(scrollTop / pageHeight) + 1;
                setCurrentPage(currentPage);
                console.log("Current Page:" ,currentPage);
            };
        };

      const middlePanelMain = document.getElementById('middle-panel-main');
      if (middlePanelMain) {
          middlePanelMain.addEventListener('scroll', handleScroll);
      }

        return () => {
            if (middlePanelMain) {
                middlePanelMain.removeEventListener('scroll', handleScroll);
            }
        };
    }, [selectedFile]);

    const removeKey = (keyToRemove) => {
        const updatedArray = {...selectedKeyValues};
        delete updatedArray[keyToRemove];
        selectedTextRef.current = [];
        setSelectedDivs((prevSet) => new Set([]));
        setSelectedKeyValues(updatedArray);
    };

    const addElementToList = (key, element) => {
        setSelectedKeyValues((prevKeyValuePairs) => {
            const updatedKeyValuePairs = {
                ...prevKeyValuePairs,
                [key]: [...(prevKeyValuePairs[key] || []), element],
            };
            return updatedKeyValuePairs;
        });
    };

    const prepareJsonTuplesPageRef = (jsonTuples) => {
        jsonTuplesPageRef.current = new Map();
        jsonTuples.forEach(([x1, y1, x2, y2, attribute, page]) => {
            jsonTuplesPageRef.current.set(page, [...(jsonTuplesPageRef.current.get(page) || []), [x1, y1, x2, y2, attribute]]);
        });
    }

    const prepareJsonExtractionCoordsPageRef = (jsonExtractionCoords) => {
        jsonExtractionCoordsPageRef.current = new Map();
        jsonExtractionCoords.forEach(([x1, y1, x2, y2, attribute, page]) => {
            jsonExtractionCoordsPageRef.current.set(page, [...(jsonExtractionCoordsPageRef.current.get(page) || []), [x1, y1, x2, y2, attribute]]);
        });
    }

    const handleMouseDown = (e) => {
        e.preventDefault();
        setIsMouseDown(true);
        const highlightedDivs = selectedKeyValues[selectedKey];
        if (highlightedDivs !== undefined && highlightedDivs.length > 0) {
            removeHighlightColorFromSelectingDivs(highlightedDivs);
            removeKey(selectedKey);
        }
    };

    const handleMouseLeave = (e) => {
        handleMouseUp(e);
    }

    const handleMouseUp = (e) => {
        e.preventDefault();
        setIsMouseDown(false);
        
        const selectedTextValues = [...selectedTextRef.current]
        if (!isSelectedKeyValidToContinue(selectedKey) || selectedTextValues.size === 0) {
            selectingDivsRefs.current = new Set();
            return;
        }

        if (selectingDivsRefs.current == null || selectingDivsRefs.current.size === 0){
            return;
        }

       /**
         * Defined this paramater to trace changes in values of_series & _bond_label.
         * They are used to update value of contexts & subContexts which use these values.
         * @param {string} seriesCurrentValue The current value of changing series.
         * @param {string} seriesNewValue The new value of changed series.
         * @param {string} subContextCurrentValue The current value of changing _bond_label.
         * @param {string} subContextNewValue The new value of changed _bond_label.
       */
        let seriesCurrentValue = '';
        let seriesNewValue = '';
        let subContextCurrentValue = '';
        let subContextNewValue = '';

        const updatedMap = structuredClone(extractedValues);
        if (Array.isArray(selectedKey)) { // If selected key is a table
            addAttributeKeyToChangedAttributeRef(selectedKey[0], changedAttributesRef.current);

            const selectedTableKey = updatedMap.get(selectedKey[0]);
            const firstArrayOfSelectedArray = selectedTableKey[0];
            let selectedRow = null;
            for (let i = 1; i < firstArrayOfSelectedArray.length; i++) { // Starts from i = 1 to Skip the header
                const innerArray = firstArrayOfSelectedArray[i];
                const index = innerArray[innerArray.length - 1]; // Index has been added to the last element of array
                if (index == selectedKey[1]) {
                    selectedRow = innerArray.find(innerInnerArray => innerInnerArray.some(element => element === selectedKey[2]));
                    break;
                }
            }
            const attributeName = removeIndexFromLabel(selectedKey[0]);
            if (selectedRow) {
                if (selectedTextValues.length === 1) {
                    if (attributeName === gtConfig.context_attribute[fileType]){
                        seriesCurrentValue = Array.isArray(selectedRow[0]) ? selectedRow[0][0] : selectedRow[0];
                        seriesCurrentValue = Array.isArray(seriesCurrentValue) ? seriesCurrentValue.join() : seriesCurrentValue;
                        seriesNewValue = selectedTextValues.join();
                    }

                    if (attributeName === gtConfig.subContext_attribute[fileType]){
                        subContextCurrentValue = Array.isArray(selectedRow[0]) ? selectedRow[0][0] : selectedRow[0];
                        subContextCurrentValue = Array.isArray(subContextCurrentValue) ? subContextCurrentValue.join() : subContextCurrentValue;
                        subContextNewValue = selectedTextValues.join();
                    }


                    selectedRow[0] = selectedTextValues.join();
                } else { // selecting multiple words
                    if (attributeName === gtConfig.context_attribute[fileType]){
                        seriesCurrentValue = Array.isArray(selectedRow[0]) ? selectedRow[0][0] : selectedRow[0];
                        seriesCurrentValue = Array.isArray(seriesCurrentValue) ? seriesCurrentValue.join() : seriesCurrentValue;
                        seriesNewValue = selectedTextValues.join('');
                        seriesNewValue = seriesNewValue.trim();
                    }

                    if (attributeName === gtConfig.subContext_attribute[fileType]){
                        subContextCurrentValue = Array.isArray(selectedRow[0]) ? selectedRow[0][0] : selectedRow[0];
                        subContextCurrentValue = Array.isArray(subContextCurrentValue) ? subContextCurrentValue.join() : subContextCurrentValue;
                        subContextNewValue = selectedTextValues.join('');
                        subContextNewValue = seriesNewValue.trim();
                    }
                    // Adding divIds object to the end of Array data of a cell [index 2]
                    selectedRow[0] = selectedTextValues.join("").trim();
                    selectedRow[2] = {[DIV_IDS]: [...selectingDivsRefs.current]};
                }
            }

            if ( attributeName === gtConfig.context_attribute[fileType]){
                updateContextOrSubContextOfAttributes(gtConfig.context_attribute[fileType], updatedMap, seriesCurrentValue, seriesNewValue);
            }

            if ( attributeName === gtConfig.subContext_attribute[fileType]){
                updateContextOrSubContextOfAttributes(gtConfig.subContext_attribute[fileType], updatedMap, subContextCurrentValue, subContextNewValue);
            }

            onInputChange(updatedMap);
        } else {
            if (selectingDivsRefs.current.size > 0) {
                addAttributeKeyToChangedAttributeRef(selectedKey, changedAttributesRef.current);
                const divTextArray = [];
                selectingDivsRefs.current.forEach(divId => {
                    const divElement = document.getElementById(divId);
                    if (divElement) {
                        const divTitle = divElement.getAttribute('title');
                        divTextArray.push(divTitle.trim());
                    }
                });

                const divTextString = divTextArray.join(' ');

                // updatedMap.set(selectedKey, [Array.from(selectedText).join("").trim(), {[DIV_IDS]: [...selectingDivs]}]);
                const currentMapItem = updatedMap.get(selectedKey);
                if(currentMapItem && Array.isArray(currentMapItem) && currentMapItem.length > 1){
                    currentMapItem[0] = divTextString;
                    currentMapItem[1] = {[DIV_IDS]: [...selectingDivsRefs.current]};
                } else {
                    updatedMap.set(selectedKey, [divTextString, {[DIV_IDS]: [...selectingDivsRefs.current]}, "","", "", "","", "", "","", "", ""]);
                }
                setSelectedDivs(selectingDivsRefs.current);
                onInputChange(updatedMap);
            }
        }
    };

    const updateContextOrSubContextOfAttributes = (attribute, updatedMap, currentValue, newValue) => {
        /**
         * If user changed a context value in context_attribute(s)[_series], but still in context_attribute(s)
         * there is another value equal to current value, don't change context value of attributes.
         */
        if (attribute === gtConfig.context_attribute[fileType]){
            const valueExist = currentValueExistInContextValues(currentValue, updatedMap);
            if (valueExist){
                return;
            }
        }

        const index = (attribute === gtConfig.context_attribute[fileType] ? 9 : 10);
        if ( newValue != null && currentValue != null && newValue !== currentValue){
            const keys = updatedMap.keys();
            for (let key of keys ){
                let attribute = updatedMap.get(key);
                if (Array.isArray(attribute) && attribute.length >= index && ( attribute[index] === currentValue || attribute[index] === undefined) ){
                    if (!isTable(attribute) && attribute[0] === 'Not Found'){
                        continue;
                    }
                    attribute[index] = newValue;
                    addAttributeKeyToChangedAttributeRef(key, changedAttributesRef.current);
                }
            }
        }
        return updatedMap;
    }


    const isSelectedKeyValidToContinue = (selectedKey) => {
        /**
         * Selected Key is Array including [updatedMap key,rowIndex, selected cell name] if a cell from table is selected
         * ex: ['form_maturity_schedule[01]', 'b24df148-5722-4466-bc0a-a9d32c5c47cf', 'Date[09]']
         */
        if (Array.isArray(selectedKey)) {
            if (selectedKey[0] === null || !extractedValues.has(selectedKey[0])) {
                return false;
            }
        } else {
            if (selectedKey === null || !extractedValues.has(selectedKey)) {
                return false;
            }
        }
        return true;
    }

    const handleMouseMove = (e) => {
        e.preventDefault();
        if (isMouseDown) {
            const el_selected = e.target
            if (el_selected !== '') {
                const ifSelectedDivExist = checkIfSelectedDivExists(el_selected.id);
                if (!ifSelectedDivExist){
                    return
                }
                const id = el_selected.id;
                let wrd = el_selected.title
                if (!selectingDivsRefs.current.has(id) && wrd !== '' ){
                    selectingDivsRefs.current.add(id);

                    const regex = /[\t\n]+/g;
                    wrd = wrd.replace(regex, ' '); //Replace \t \n with space
                    
                   // setSelectedText((prevList) => [...prevList, wrd]);
                    selectedTextRef.current.push(wrd);
                                     
                    let clonedSelectedKey = {...selectedKey}
                    if (Array.isArray(selectedKey)) {
                        clonedSelectedKey = selectedKey[2]
                    }
                    addElementToList(clonedSelectedKey, el_selected.id)
                    el_selected.style.backgroundColor = 'rgba(255, 255, 0, 0.7)';                  
                }
            }
        }
    };

    const checkIfSelectedDivExists = (id) => {
        return Array.from(textDivRefs.current.values()).some((ids) => ids.includes(id));
    }

    useEffect(() => {
            // const handleMouseOver = (attribute) => {
            //     return () => {
            //         if (!document.getElementById(attribute) == null) {
            //             document.getElementById(attribute).style.backgroundColor = '#cccccc';
            //             document.getElementById('text_' + attribute).style.backgroundColor = '#cccccc';
            //         }
            //     };
            // }

            // const handleMouseOut = (attribute) => {
            //     return () => {
            //         if (!document.getElementById(attribute) == null) {
            //             document.getElementById(attribute).style.backgroundColor = 'white';
            //             document.getElementById('text_' + attribute).style.backgroundColor = 'white';
            //         }
            //     };
            // }

            const loadPdfWithAnnotations = async () => {
                console.log("Loading pdf with Annotations.")
                const abortController = new AbortController();
                abortControllerRef.current = abortController;
                if (currentPdfFilePath !== selectedFile) {
                    console.log('Selected file has changed. Skipping loadPdfWithAnnotations.');
                    return;
                }
                document.querySelector('.middle-panel').scrollTop = 0;

                if (pdfContainerRef.current) {
                    while (pdfContainerRef.current.firstChild) {
                        pdfContainerRef.current.removeChild(pdfContainerRef.current.firstChild);
                    }
                }

                try {
                    const pdfjsScript = document.createElement('script');
                    pdfjsScript.src = gtConfig.pdfjsScript_src;
                    document.body.appendChild(pdfjsScript);                  

                    pdfjsScript.onload = async () => {
                        initializePdf(abortController.signal);
                    };
                } catch (error) {
                    console.log(error.message);
                }

            };
            
            const clearContainer = () => {
                // divRefs.current = [];
              //  textDivRefs.current = new Map();
            };
            clearContainer();

            const initializePdf = async (signal) => {
                try {
                    loadedPdfPagesRef.current = new Map();
                    pdfPagesRef.current = new Map();
                    pdfContainerRef.current.innerHTML = '';
                    // Render the PDF
                    canvasMapForOcrRefs.current = new Map(); // Clear the canvasMapForOcrRefs before loading new pdf
                    redOcrDivRefs.current = new Map();
                    pdfRedOcrDivRefs.current = new Map();
                    
                    const numPages = pdfDoc.numPages;
                    for (let pageNum = 1; pageNum <= numPages;pageNum++) {
                        if (signal.aborted) {
                            console.log('PDF loading aborted.');
                            return;
                        }
                      const page = await pdfDoc.getPage(pageNum);

                      pdfPagesRef.current.set(pageNum, page);
                      const canvas = document.createElement('canvas'); // Create canvas to attach the pdf page
  
                      pdfContainerRef.current.appendChild(canvas);

                      let scale = 1.7;
                      
                      let viewport = null;
                      viewport = page.getViewport({scale});
                      
                      /*
                      * Ticket-ADO-47829    
                      * For this use case to fix the issue I needed to change the scale before getting viewport!
                      * viewBox: [ 666, 63.1702, 1286.22, 855.442]
                      */
                      if ( viewport.viewBox && viewport.viewBox.length > 2 && viewport.viewBox[0] != 0 && viewport.viewBox[1] != 0 && viewport.viewBox[2] > 1200){
                          scale = 1.36; //EX YY2023_MM09_05164HBA0_OS_il619.pdf (Page 1)
                          viewport = page.getViewport({scale});
                      }
                                              
      
                      /*
                          Ticket ADO-33653: In pdf 477, the first page had different viewport value.
                          That caused the annotation not to be alligned with the words in the first page.
                          Changed the scale and value of viewBox (provided same values as other pages) to fixed the issue.
                      */                
                      if (viewport.viewBox && viewport.viewBox.length > 2 && viewport.viewBox[0] !== 0 && viewport.viewBox[0] !== 0){
                          if (viewport.viewBox[0] < 1){
                              scale = 1.40;  // Ex: O83317.pdf 
                          } else {
                              scale = 1.37;
                          }

                          viewport.viewBox = [0, 0, 612, 792]
                      }

                      /*
                          #Ticket  ADO-60328
                          ex: YY2024_MM09_836246MJ4_P92679_BondCall.pdf , YY2024_MM09_57586P5G7_P92816_BondCall.pdf, YY2024_MM09_54721PAA7_P92822_BondCall.pdf
                      */
                      if (viewport.viewBox && viewport.viewBox.length === 4 && viewport.viewBox[0] === 0 && viewport.viewBox[1] === 0 &&
                          viewport.viewBox[2] === 595  && viewport.viewBox[3]  === 842 ){
                          scale = 1.73;
                      }

                      canvas.width = viewport.width - 32; // added 32 since canvas is overflowing the RightPanel
                      canvas.height = viewport.height;
                      // canvas.style.opacity = 0;

                      /*
                          Ticket ADO-45027: context.clearRect(...) is resetting left/top value of canvas since canvas 
                          is inheriting the values from context.
                          A bug is cauing the value of canvas.left & canvas.top stays zero and causing this issue.
                          Cloned the values here and used them in containe.style to fix the issue.
                      */
                      const leftCanvasClone = canvas.offsetLeft;
                      const topConvasClone = canvas.offsetTop;

                        /* Obtain the 2D rendering context for a <canvas> element in HTML. 
                      *  It is needed to render pdf page content in the canvas and attach red/gray rectangles
                      */
                      const context = canvas.getContext('2d');
                      context.clearRect(0, 0, canvas.width, canvas.height);

                      const frameOffsetLeft = pdfContainerRef.current.offsetLeft;
                      const frameOffsetTop = pdfContainerRef.current.offsetTop;
                      const padding = 1;

                      if (!jsonExtractionCoords) {
                          console.log("In loading pdf file, jsonExtractionCoords is null.")
                          return
                      }
                      const jsonExtractionCoordsSize = Object.keys(jsonExtractionCoords).length;
                      const jsonTuplesSize = Object.keys(jsonTuples).length;

                      /* Create overlay div to attach the attribute divs.
                       * This div overlays the canvas and is used to attach the attribute divs.
                      */
                      const container = document.createElement('div');

                      container.id = `container_${pageNum}`;
                      container.style.position = 'absolute';
                      container.style.left = `${leftCanvasClone}px`;
                      container.style.top = `${topConvasClone}px`;
                      container.style.width = `${canvas.width}px`;
                      container.style.height = `${canvas.height}px`;
                      pdfContainerRef.current.appendChild(container);

                      canvasMapForOcrRefs.current.set(pageNum,
                         {
                          'scale': scale, 
                          'padding': padding, 
                          'top':  topConvasClone, 
                          'left': leftCanvasClone,
                          'canvas': canvas,
                          'hasOcr' : false,
                          'context': context,
                          'container':container,
                          'viewport': viewport
                          }
                        );

                      while (container.firstChild) {
                          container.removeChild(container.firstChild);
                      }

                      if (jsonExtractionCoordsSize > 0 && jsonTuplesSize > 0) {
                         addDivsToPredefinedLocations(jsonExtractionCoords, pageNum, context, scale, padding, viewport, frameOffsetLeft, frameOffsetTop, canvas, pdfContainerRef);
                        }

                      /*
                      * Same functionality as addDivTuplesToOCRPages for pdf but just for one page.
                      * This is used to render first n pdf pages when the pdf is loaded initialy to show pdf page faster and doesn't wait all canvases be rendered.
                      */ 
                      const pageTolerance = gtConfig.page_tolerance;
                      if ( pageNum > 0  && pageNum <= pageTolerance){
                         addDivTuplesToOCRPageInInit(pageNum);
                      }
   
                    }
                    
                    if (searchFeatureChecked){
                        prepareSearchDocumentData();
                    }
                } catch (error) {
                    if (error.name === 'AbortError') {
                        console.log('PDF loading aborted:', error.message);
                    } else {
                        console.error('Error loading PDF:', error);
                    }
                }
            };

            const prepareSearchDocumentData = () => {
                const textDivMap = new Map();
                const divIdTextMap = new Map();
        
                let index = 0;
                jsonTuples.forEach(([x1, y1, x2, y2, attribute, page]) => {
                    attribute = attribute.trim().toLowerCase();
                    const canvasDetail = canvasMapForOcrRefs.current.get(page);
                    if (canvasDetail === undefined){
                      //  console.log('Search Detail: Canvas detail is not available for page:', page);
                        return;
                    }
        
                    const attributeDetail = {'index': index,'x1': x1, 'y1': y1, 'x2': x2,'y2': y2,
                            'scale':canvasDetail.scale, 'padding': canvasDetail.padding, 'canvas': canvasDetail };
                    if (textDivMap.has(attribute)){
                        textDivMap.get(attribute).push(attributeDetail);
                    } else {
                        textDivMap.set(attribute,[attributeDetail]);
                    }
                    divIdTextMap.set(index, attribute);
        
                    index = index + 1;
                });
                setTextDivs(textDivMap);
                setDivIdText(divIdTextMap);
            }


            const addDivsToPredefinedLocations = (jsonExtractionCoords, pageNum, context, scale, padding, viewport, frameOffsetLeft, frameOffsetTop, canvas, pdfContainerRef) => {
                jsonExtractionCoords.forEach(([x1, y1, x2, y2, attribute, pg]) => {
                    if (pg === pageNum) {
                        context.strokeStyle = 'white';
                        context.lineWidth = 0.1;
                        x1 = x1 * scale;
                        y1 = y1 * scale;
                        x2 = x2 * scale;
                        y2 = y2 * scale;
                        context.strokeRect(x1 - padding, y1 - padding, x2 - x1 + padding * 2, y2 - y1 + padding * 2);

                        const pdfRect = convertToPdfCoordinates([x1 * scale, y1 * scale, x2 - x1, y2 - y1], viewport);
                        const div = document.createElement('div');
                        div.id = attribute + '_overlay';
                        div.style.position = 'absolute';
                        div.style.zIndex = -1;
                        if (pg >= 1) {
                            div.style.left = `${pdfRect[0] + frameOffsetLeft}px`;
                            div.style.top = `${pdfRect[1] + frameOffsetTop + 5 + ((pg - 1) * (canvas.height + 3.4))}px`;
                            div.style.width = `${pdfRect[2] - pdfRect[0] + 8}px`;
                            div.style.height = `${pdfRect[3] - pdfRect[1]}px`;
                            div.style.borderBottom = '';
                            div.style.borderTop = '';
                            div.title = attribute; // Set tooltip text

                            // div.addEventListener('mouseover', handleMouseOver(attribute));
                            // div.addEventListener('mouseout', handleMouseOut(attribute));
                            // divRefs.current.push(div);
                        }
                       
                        pdfContainerRef.current.appendChild(div);
                    }
                });
            }

            loadPdfWithAnnotations();
            return () => {
                // Abort the ongoing loading process if the component unmounts
                abortControllerRef.current.abort();
            };
        },
        [currentPdfFilePath, jsonExtractionCoords]);


    useEffect(() => {
        setCurrentPdfFilePath(selectedFile);
    }, [selectedFile, jsonExtractionCoords]);

    async function addDivTuplesToOCRPageInInit(pageNum){
        const canvasMapForOcr = canvasMapForOcrRefs.current.get(pageNum);
        if ( !canvasMapForOcr) return;
        
        const canvas = canvasMapForOcr.canvas;
        if (canvas) { 
            canvas.style.opacity = 1; 
        }

        if ( loadedPdfPagesRef.current.has(pageNum)) return;

        const controller = new AbortController();
        const signal = controller.signal;
        try {
            const viewport = canvasMapForOcr.viewport;
            const page =  pdfPagesRef.current.get(pageNum);

            if (canvasMapForOcr.isRendering) {
                console.log(`Rendering already in progress for page ${pageNum}`);
                return; // Skip to the next page if rendering is in progress
            }

            // Set the rendering flag
            canvasMapForOcr.isRendering = true;

            await page.render({ canvasContext: canvasMapForOcr.context, viewport, signal }).promise;
            
            loadedPdfPagesRef.current.set(pageNum, canvasMapForOcr);
            const canvasMapForOcrPage = canvasMapForOcrRefs.current.get(pageNum);
            addRedOcrDivToPage(jsonExtractionCoordsPageRef, pageNum, middlePanelContentType, canvasMapForOcrPage, redOcrDivRefs, pdfRedOcrDivRefs);

        } catch (error) {
            if (signal.aborted) {
                console.log('Rendering aborted');
            } else {
                console.error('Error rendering page:', error);
            }
        } finally {
            canvasMapForOcr.isRendering = false;
        }
    }

    /*
    * We should change the zIndex of the redOcrDivs based on the middlePanelContentType.
    * Otherwise red ocr div of pdf will be shown on top of the text divs and user couldn't select texts.
    */
    function visibleOcrDivs( redOcrDivRefs, pdfRedOcrDivRefs, middlePanelContentType,pageNum){
        const ocrDivIds = redOcrDivRefs.current.get(pageNum);
        if (ocrDivIds){
            ocrDivIds.forEach(divId => {
                var div = document.getElementById(divId);
                if (div){
                    div.style.zIndex = middlePanelContentType === 'pdf' ? 1 : -1;
                }
            }); 
        }     

        const pdfDivIds = pdfRedOcrDivRefs.current.get(pageNum);
        if(pdfDivIds){
            pdfDivIds.forEach(divId => {
                var div = document.getElementById(divId);
                if (div){
                    div.style.zIndex = middlePanelContentType === 'pdf' ? 1 : -1;
                }
            });
        }
    }

    async function addDivTuplesToOCRPages() { 
        clearDivMapBaseOnCurrentPage(textDivRefs, currentPage, gtConfig.page_tolerance, middlePanelContentType);
        clearDivMapBaseOnCurrentPage(redOcrDivRefs, currentPage, gtConfig.page_tolerance, middlePanelContentType);
        clearDivMapBaseOnCurrentPage(noOcrDivRefs, currentPage, gtConfig.page_tolerance, middlePanelContentType);       

        clearContextsBasedOnCurrentPage(loadedPdfPagesRef, currentPage, gtConfig.page_tolerance,pdfRedOcrDivRefs );

        if ( middlePanelContentType === 'pdf'){
            const pageTolerance = gtConfig.page_tolerance;

            const controller = new AbortController();
            const signal = controller.signal;

            const renderPages = async () => {
                // const startPage = Math.max(0, currentPage - pageTolerance);
                // const endPage   =  currentPage + pageTolerance;
                for (let pageNum = currentPage - pageTolerance; pageNum <= currentPage + pageTolerance; pageNum++) {                   
                
                    const canvasMapForOcr = canvasMapForOcrRefs.current.get(pageNum);
                    if ( !canvasMapForOcr) continue;
                    
                    const canvas = canvasMapForOcr.canvas;
                    if (canvas) { 
                        canvas.style.opacity = 1; 
                    }

                    if ( loadedPdfPagesRef.current.has(pageNum)){
                        visibleOcrDivs( redOcrDivRefs, pdfRedOcrDivRefs, middlePanelContentType, pageNum);
                        continue;
                    }

                    try {
                        const viewport = canvasMapForOcr.viewport;
                        const page =  pdfPagesRef.current.get(pageNum);

                        if (canvasMapForOcr.isRendering) {
                            console.log(`Rendering already in progress for page ${pageNum}`);
                            continue; // Skip to the next page if rendering is in progress
                        }

                        // Set the rendering flag
                        canvasMapForOcr.isRendering = true;

                        await page.render({ canvasContext: canvasMapForOcr.context, viewport, signal }).promise;
                       
                        loadedPdfPagesRef.current.set(pageNum, canvasMapForOcr);
                        const canvasMapForOcrPage = canvasMapForOcrRefs.current.get(pageNum);
                        addRedOcrDivToPage(jsonExtractionCoordsPageRef, pageNum, middlePanelContentType, canvasMapForOcrPage, redOcrDivRefs, pdfRedOcrDivRefs);

                    } catch (error) {
                        if (signal.aborted) {
                            console.log('Rendering aborted');
                            break;
                        } else {
                            // multiple render error: It can happen when user scrolls while initial pdf rendering is in progress.
                            console.error('Error rendering page:', error);
                        }
                    } finally {
                        canvasMapForOcr.isRendering = false;
                    }
                }
            };
            renderPages();

            
            
            return () => {
                controller.abort();
            }; 

        } else {
            const pageTolerance = gtConfig.page_tolerance;
            let index = 0;
            for (let pageNum = currentPage - pageTolerance; pageNum <= currentPage + pageTolerance; pageNum++) {
                if (pageNum < 1) continue;
                
                // Checking if canvas has been created for the page. That could happen when user scrolls to a page 
                // that it's canvas has not been created yet.
                const canvasMapForOcrPage = canvasMapForOcrRefs.current.get(pageNum);
                if (!canvasMapForOcrPage){
                    console.log(`No canvasMapForOcrPage for page ${pageNum}`);
                    continue;
                }

                const canvas = canvasMapForOcrPage.canvas;
                if (canvas) { 
                    canvas.style.opacity = 0; 
                }
                
                visibleOcrDivs( redOcrDivRefs, pdfRedOcrDivRefs, middlePanelContentType, pageNum);

                if (textDivRefs.current.has(pageNum)) continue;

                const jsonTuplesAttributes =  jsonTuplesPageRef.current.get(pageNum);
                if (!jsonTuplesAttributes){
                    console.log(`No OCR jsonTuple for page ${pageNum}`);
                    //Adding no OCR image for the pages which don't have OCR
                    createNoOcrImage(pageNum, noOcrDivRefs);
                    continue;
                }
                            
                const scale = canvasMapForOcrPage.scale;
                const padding = canvasMapForOcrPage.padding;


                for (let i = 0; i < jsonTuplesAttributes.length; i++) {
                    const [x1, y1, x2, y2, attribute] = jsonTuplesAttributes[i];
                    const divTuple = document.createElement('div');

                    const divIndex = 'page_' + pageNum + '_div_' + index;
                    divTuple.id = divIndex;
                    divTuple.style.position = 'absolute';
                    const x1_scaled = x1 * scale;
                    const y1_scaled = y1 * scale;
                    const x2_scaled = x2 * scale;
                    const y2_scaled = y2 * scale;
                    divTuple.style.left = `${ x1_scaled - canvasMapForOcrPage.padding}px`;
                    divTuple.style.top = `${ y1_scaled - canvasMapForOcrPage.padding }px`;

                    divTuple.style.width = `${x2_scaled - x1_scaled + padding * 2}px`;
                    divTuple.style.height = `${y2_scaled - y1_scaled + padding * 2}px`;
                    divTuple.title = attribute;
                    divTuple.style.border = gtConfig.showOcrDivAnnotations ? '1px solid #e3dddd' : ''; // Add border to the div
                    divTuple.innerText = attribute;
                    divTuple.style.fontSize = `${fontValue}px`;
                    canvasMapForOcrPage.container.appendChild(divTuple);

                    const pageDivs= textDivRefs.current.get(pageNum) || [];
                    pageDivs.push(divIndex);
                    textDivRefs.current.set(pageNum, pageDivs);
                    
                    index = index + 1;
                }

                //Add red OCR divs
                if (redOcrDivRefs.current.has(pageNum)) continue;

                addRedOcrDivToPage(jsonExtractionCoordsPageRef, pageNum, middlePanelContentType, canvasMapForOcrPage, redOcrDivRefs, pdfRedOcrDivRefs);

            }
        }
    }

    return (
        <Box id='middle-panel' className="middle-panel" style={{textAlign: 'center', backgroundColor: 'white'}}>
             <Box 
                id='middle-panel-main' 
                style={{
                    position: 'relative',
                    overflow: 'auto',
                    minHeight: '93vh',
                    maxHeight: '93vh',
                    scrollBehavior: 'smooth',
                    willChange: 'transform',
                }}

                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseLeave}
                    onMouseMove={handleMouseMove}>
                    <Box  id='middle-panel-pdf' style={ isLoading ? {...loadingStyle} : undefined} ref={pdfContainerRef}></Box>
            </Box>
        </Box>
    );
};

function addRedOcrDivToPage(jsonExtractionCoordsPageRef, pageNum, middlePanelContentType, canvasMapForOcrPage, redOcrDivRefs, pdfRedOcrDivRefs){
    
    const extractionCoords =  jsonExtractionCoordsPageRef.current.get(pageNum);
    if (!extractionCoords){
      //  console.log(`No OCR extractionCoords for page ${pageNum}`);
        return;
    }

    for (let i = 0; i < extractionCoords.length; i++) {
        const [x1, y1, x2, y2, attribute] = extractionCoords[i];
        
        const scale = canvasMapForOcrPage.scale;
        const padding = canvasMapForOcrPage.padding;
        const leftMargin = 2;
        const topMargin = 2;

        const div = document.createElement('div');
        const x1_scaled = x1 * scale;
        const y1_scaled = y1 * scale;
        const x2_scaled = x2 * scale;
        const y2_scaled = y2 * scale;
        
        const redDivId = `${middlePanelContentType}_ocr_red_${pageNum}_${x1}_${y1}_${x2}_${y2}`;
        div.id = redDivId;
        div.style.position = 'absolute';
        div.style.left = `${x1_scaled - leftMargin}px`;
        div.style.top = `${y1_scaled - topMargin}px`;
        div.style.width = `${x2_scaled - x1_scaled + padding * 4}px`;
        div.style.height = `${y2_scaled - y1_scaled + padding * 4}px`;
        div.style.border = '0.5px solid red';
        div.style.boxSizing = 'border-box';
        // div.style.zIndex = -1;
        div.style.zIndex = middlePanelContentType === 'ocr' ? -1 : 1;
      

        if (middlePanelContentType === 'ocr'){
            const pageRedDivs= redOcrDivRefs.current.get(pageNum) || [];
            if (!pageRedDivs.includes(redDivId)){
                pageRedDivs.push(redDivId);
                canvasMapForOcrPage.container.appendChild(div);
                redOcrDivRefs.current.set(pageNum, pageRedDivs);
            }
        } else {
            const pageRedDivs= pdfRedOcrDivRefs.current.get(pageNum) || [];
            if (!pageRedDivs.includes(redDivId)){
                canvasMapForOcrPage.container.appendChild(div);
                pageRedDivs.push(redDivId);
                pdfRedOcrDivRefs.current.set(pageNum, pageRedDivs);
            }
        }
    }
}


function createNoOcrImage(pageNum, noOcrDivRefs) {
    if (noOcrDivRefs.current.has(pageNum)) return;

    var divContextDiv = document.getElementById(`div_container_${pageNum}`);
    var contextDiv = document.getElementById(`container_${pageNum}`);
    if (contextDiv && !divContextDiv) {
        const newDiv = document.createElement('div');
        const newDivId = `div_container_${pageNum}`;
        newDiv.id = newDivId;
        const img = document.createElement('img');
        img.src = './assets/no-ocr.PNG';
        img.alt = 'NO OCR Image';
        img.style.width = '400px';
        img.style.height = '100px';

        newDiv.appendChild(img);

        contextDiv.appendChild(newDiv);
        noOcrDivRefs.current.set(pageNum, [newDivId]);
    }
}

function convertToPdfCoordinates(rectangle, viewport) {
    const x = (rectangle[0] / viewport.width) * viewport.viewBox[2] + viewport.viewBox[0];
    const y = (rectangle[1] / viewport.height) * viewport.viewBox[3] + viewport.viewBox[1];
    const width = (rectangle[2] / viewport.width) * viewport.viewBox[2];
    const height = (rectangle[3] / viewport.height) * viewport.viewBox[3];

    return [x, y, x + width, y + height];
}

export default MiddlePanel;
import { Box, Button, Chip } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import {useTheme} from "@mui/material/styles";

function SearchDocumentComponent({textDivs, divIdText, searchFeatureChecked, headerHeight}) {
    const [searchText, setSearchText] = useState('');
    const [currentResultIndex, setCurrentResultIndex] = useState(0);
    const [searchResults, setSearchResults] = useState(undefined);
    const [wordCount, setWordCount] = useState(0);

    const searchInputRef = useRef(null);
    const searchButtonRef = useRef(null);
    const nextButtonRef = useRef(null);

    const currentDivWordIdRef = useRef(null);

    const [isSearchClicked, setIsSearchClicked] = useState(false);

    const theme = useTheme();
    
    // Use this state to remove style from div when searchFeatureChecked is false
    const [selectedDivId, setSelectedDivId] = useState(0);


    useEffect(() => {
        setCurrentResultIndex(0);
        setSearchText("");
        setSearchResults(undefined);
    }, [textDivs]);

    useEffect(() => {
        if (!searchFeatureChecked){
            scrollToSelectedDivs(selectedDivId, false, wordCount)
        }
    }, [searchFeatureChecked]);

    useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.key === 'Enter') {
            if (document.activeElement === searchInputRef.current) {
                searchButtonRef.current.focus();
                searchButtonRef.current.click();
                nextButtonRef.current.focus();
            } else if ( document.activeElement === searchButtonRef.current || document.activeElement === nextButtonRef.current){
                nextButtonRef.current.focus();
                nextButtonRef.current.click();
            }

          }
        };
        document.addEventListener('keydown', handleKeyDown);
    
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, []);

    const scrollToSelectedDivs = (divDetail, isSelect, wordCount) => {
        const styleValue = isSelect ? "5px solid blue" : "";
        for (let i = 0; i < wordCount; i++){
            removeCurrentSearchDiv();
            const divTuple = document.createElement('div');
            
            const searchDivId = 'searchdiv';
            divTuple.id = searchDivId;
            divTuple.style.position = 'absolute';

            //const attributeDetail = {'index': index,'x1': x1, 'y1': y1, 'x2': x2,'y2': y2, 'scale':canvasDetail.scale, 'padding': canvasDetail.padding,  'canvas': canvasDetail};
            const { x1, y1, x2, y2, scale, padding, canvas} = divDetail;
            
            const x1_scaled = x1 * scale;
            const y1_scaled = y1 * scale;
            const x2_scaled = x2 * scale;
            const y2_scaled = y2 * scale;

            divTuple.style.left = `${ x1_scaled - padding}px`;
            divTuple.style.top = `${ y1_scaled - padding }px`;

            divTuple.style.width = `${x2_scaled - x1_scaled + padding * 2}px`;
            divTuple.style.height = `${y2_scaled - y1_scaled + padding * 2}px`;

    
            divTuple.style.borderBottom = styleValue
           
            currentDivWordIdRef.current = searchDivId;
            canvas.container.appendChild(divTuple);
            divTuple.scrollIntoView({block: "center", inline: "nearest"});
        }
    }
    

    const removeCurrentSearchDiv = () => {
        const divId = currentDivWordIdRef.current;
        const divToRemove = document.getElementById(divId);
        if (divToRemove) {
            divToRemove.remove();
        }
        currentDivWordIdRef.current = null;
    }

    const handleSearch = async () => {
        setIsSearchClicked(true);
        // clean previous div blue style
        if ( searchResults !== undefined && searchResults.length > 0 && currentResultIndex >= 0){
            scrollToSelectedDivs( searchResults[currentResultIndex], false, wordCount);
        }
        
        const words = searchText.trim().split(' ');
        setWordCount(words.length);
        const firstWord = words[0];
        const firstWordDivDetails = textDivs.get(firstWord);
        let results = [];
        if ( firstWordDivDetails && firstWordDivDetails.length > 0){
            for ( let firstWordDivDetail of firstWordDivDetails ){
                if (isTextEqualToSearchText(words, firstWordDivDetail)){
                    results.push(firstWordDivDetail);
                }
            }
           
            if (results.length > 0){
                setSearchResults(results);
                setSelectedDivId(results[0]);
                scrollToSelectedDivs( results[0], true, words.length);
            } else {
                setSearchResults(undefined);
            }
            
        } else {
            setSearchResults(undefined);
        }
    
        setCurrentResultIndex(0);
    };

    const isTextEqualToSearchText = (words, firstWordDivDetail) => {
        const index = firstWordDivDetail.index;
        let text = words[0];
        for (let i = 1; i < words.length; i++){
            text = text + ' ' + divIdText.get(index + i)
        }
        return text === searchText.trim();
    }

    const handleNextResult = () => {
        if (currentResultIndex < searchResults.length - 1) {
            setCurrentResultIndex(currentResultIndex + 1);
            setSelectedDivId(searchResults[currentResultIndex + 1]);
            scrollToSelectedDivs( searchResults[currentResultIndex], false, wordCount);
            scrollToSelectedDivs( searchResults[currentResultIndex + 1], true, wordCount);
        }
    };

    const handlePreviousResult = () => {
        if (currentResultIndex > 0) {
            setCurrentResultIndex(currentResultIndex - 1);
            setSelectedDivId(searchResults[currentResultIndex - 1]);
            scrollToSelectedDivs( searchResults[currentResultIndex], false, wordCount);
            scrollToSelectedDivs( searchResults[currentResultIndex - 1], true, wordCount);
        }
    };

    return (
        // <Box sx={{borderColor: theme.palette.divider, backgroundColor: theme.palette.background.paper}}>
        <Box sx={{borderColor: theme.palette.divider, width:"650px"}}>
              { searchResults?.length > 0 && 
                <Chip 
                    label={`${currentResultIndex + 1} of ${searchResults?.length}`} 
                    variant="outlined"  
                    sx={{ height: '22px', margin:'2px 1px 2px 2px', fontSize: '12px'}}
                />
            }            
            <input
                ref={searchInputRef}
                style={{width: "150px"}}
                type="text"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value.toLocaleLowerCase())}
                placeholder="Search text..."
                disabled={!searchFeatureChecked}
            />
            <Button
                sx={{height: "22px", marginLeft: "2px"}}
                variant="contained" 
                size="small"
                ref={searchButtonRef}
                onClick={handleSearch}
                disabled={textDivs.size === 0 || !searchFeatureChecked}>Search
            </Button>
            <Button
                sx={{height: "22px", marginLeft: "2px"}}
                variant="contained" 
                size="small"
                onClick={handlePreviousResult}
                disabled={searchResults === undefined || currentResultIndex === 0 || !searchFeatureChecked}>Previous
            </Button>
            <Button 
                sx={{height: "22px", marginLeft: "2px"}}
                variant="contained" 
                size="small"
                ref={nextButtonRef}
                onClick={handleNextResult}
                disabled={searchResults === undefined || currentResultIndex === searchResults.length - 1 || !searchFeatureChecked}>Next
            </Button>
        </Box>
    )
}

export default SearchDocumentComponent
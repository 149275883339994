import React, {useCallback, useEffect, useState, useMemo} from 'react';
import * as XLSX from 'xlsx';
import '../../App.css';
import ConfirmationStatsPopup from './ConfirmationStatsPopup';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Box, Button, Typography} from "@mui/material";
import {makeStyles} from '@mui/styles';
import {styled} from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import LeftPanelOrderBy from './LeftPanelOrderBy';
import gtConfig from '../../config.js';
import {useThemeContext} from '../../theme/ThemeContextProvider.jsx';
import {useTheme} from "@mui/material/styles";
import ValidationIssueModal from './ValidationIssueModal.jsx';
import LockDocumentsModal from './LockDocumentsModal.jsx';
import AuditModal from './AuditModal.jsx';
import { FixedSizeList as List } from 'react-window';
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faAnglesRight} from '@fortawesome/free-solid-svg-icons';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}}/>}
        {...props}
    />
))(({theme}) => ({
    backgroundColor: 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper': {
        transform: 'rotate(-90deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
    ...theme.applyStyles('dark', {
        backgroundColor: 'rgba(255, 255, 255, .05)',
    }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const useAccordionStyles = makeStyles({
    root: {
        //fontFamily: 'Arial',
        //width: '280px',
        fontSize: '12px',
        margin: '0px',
        padding: '0.5px',
        '&.MuiAccordion-root': {
            height: '40px',
        },
        '&.MuiAccordion-root.Mui-expanded': {
            height: 'auto',
        },
    },
});

const AccordionItems = React.memo(({
                                       fileType,
                                       content,
                                       isExpanded,
                                       handleAccordionClick,
                                       selectedFile,
                                       onFileSelectHandler,
                                       getfileModifiedDate,
                                       handleClick
                                   }) => {
    const accordionClasses = useAccordionStyles();
    const theme = useTheme();
    const {themeMode, toggleColorMode} = useThemeContext();
    const [listHeight, setListHeight] = useState(250);

    useEffect(() => {
        const updateListHeight = () => {
            const availableHeight = window.innerHeight - 550; // Adjust 200 based on your layout
            setListHeight(availableHeight);
        };

        updateListHeight();
        window.addEventListener('resize', updateListHeight);

        return () => {
            window.removeEventListener('resize', updateListHeight);
        };
    }, []);

    const Row = ({index, style}) => {
        const [file, categories] = Object.entries(content)[index];
        return (
            <li key={index} className={selectedFile === file ? `left-panel-selected-${themeMode}` : null} style={style}>
                <table border="0" width={'100%'} cellSpacing="0" cellPadding="0">
                    <tbody>
                        <tr>
                            <th colSpan="3" style={{ textAlign: 'left' }}>
                                <u title={file.replace('|', ' ')}>{file.replace('|', ' ')}</u>
                            </th>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'left' }}>
                                {categories.map((category, categoryIndex) => (
                                    <div key={categoryIndex} style={{ display: 'flex', justifyContent: 'space-between', padding: 0, margin: 0}}>
                                        <div>
                                            <Button
                                                size='small'
                                                component='a'
                                                sx={{ fontSize: '0.5rem' }}
                                                onClick={() => onFileSelectHandler(file, fileType, category)}
                                            >
                                                {category}
                                            </Button>
                                        </div>
                                        <div>
                                            <Typography variant='caption' sx={{ color: theme.palette.text.primary, fontSize: "0.6rem" }}>
                                                {(category === 'extraction' || category === 'gt_ops_confirmed') ? getfileModifiedDate(file, category) : ''}
                                            </Typography>
                                        </div>
                                    </div>
                                ))}
                                <Button
                                    size='small'
                                    component='a'
                                    sx={{ fontSize: '0.5rem' }}
                                    onClick={() => handleClick(file.replace('|', ' '), fileType)}
                                >
                                    Time Machine
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </li>
        );
    }

    return (
        <Accordion
            className={accordionClasses.root}
            style={{backgroundColor: theme.palette.background.paper}}
            expanded={isExpanded}
            onChange={handleAccordionClick}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="accordian-content"
                id="accordian-header"
            >
                <Typography variant="body" sx={{mb: 0}} mt={0} fontWeight="bold"
                            color={theme.palette.text.primary}>{fileType}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{maxHeight: '50vh', overflowY: 'hidden', padding: '0'}}>
                <div style={{maxHeight: 'auto'}}>
                    <ul style={{margin: 0}}>
                    <List
                        height={listHeight}
                        itemCount={Object.keys(content).length}
                        itemSize={80}
                        width='auto'
                    >
                        {Row}
                    </List>
                    </ul>
                </div>
            </AccordionDetails>
        </Accordion>
    );
});


const LeftPanel = ({
                       files,
                       onFileSelect,
                       totalFieldsConfirmed,
                       isLoading,
                       navOrderBy,
                       setNavOrderBy,
                       isExternalUse,
                       externalDetails,
                       isLockDocumentModalOpen,
                       isValidationIssueModalOpen,
                       setValidationIssueModalOpen,
                       setLockDocumentModalOpen,
                   }
) => {

    const {themeMode, toggleColorMode, setColorMode} = useThemeContext();
    const theme = useTheme();

    const [searchTerm, setSearchTerm] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [isRightPanelVisible, setIsRightPanelVisible] = useState(true);
    const [totalFiles, setTotalFiles] = useState(0);
    const [totalFilesConfirmed, setTotalFilesConfirmed] = useState(0);
    const [totalFields, setTotalFields] = useState(0);
    const [selectedFileType, setSelectedFileType] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [filteredFiles, setFilteredFiles] = useState();
    const [fileTypes, setFileTypes] = useState([]);

    const [categorizedFiles, setCategorizedFiles] = useState(() => {
            const categorizedFilesTemplate = {};
            Object.entries(files).map(([fileName, fileEntry], index) => {
                const fileType = fileEntry.file_type;
                const category = fileEntry.category;
                if (!categorizedFilesTemplate[fileType]) {
                    categorizedFilesTemplate[fileType] = {};
                }
                categorizedFilesTemplate[fileType][fileEntry.file_name] = category;
            });

            return categorizedFilesTemplate;
        }
    );

    const fileModifiedDateMap = useMemo(() => {
        return Object.entries(files).reduce((acc, item) => {
            const fileName = item[1].file_name;
            acc[fileName] = item[1]['modified_date'];
            return acc;
        }, {});
    }, [files]);


    const [expanded, setExpanded] = useState(() => {
        const index = localStorage.getItem('leftPanelAccordionsStatus');
        return index ? parseInt(index) : null;
    });

    useEffect(() => {
        provideInitialFilterValue();
        if (isExternalUse) {
            setColorMode(externalDetails.themeMode);
            setSelectedId(externalDetails.documentName);
            setSearchTerm(externalDetails.documentName);
            changedSearchTerm(externalDetails.documentName);
            onFileSelectHandler(externalDetails.documentName, externalDetails.doc_type, 'extraction');
        } else {
            setSearchTerm('');
        }

    }, [files]);


    useEffect(() => {
        localStorage.setItem('leftPanelAccordionsStatus', expanded);
    }, [expanded]);

    useEffect(() => {
        // Calculate confirmation stats
        const totalFilesCount = Object.keys(files).length;
        const totalFieldsCount = Object.values(files).reduce(
            (count, file) => count + Object.keys(file).length,
            0
        );

        const confirmedFilesCount = Object.values(files).filter(file => file.confirmed).length;
        const confirmedFieldsCount = Object.values(files).reduce(
            (count, file) => count + Object.keys(file).filter(field => file[field].confirmed).length,
            0
        );

        setTotalFiles(totalFilesCount);
        setTotalFilesConfirmed(confirmedFilesCount);
        setTotalFields(totalFieldsCount);
        //setTotalFieldsConfirmed(confirmedFieldsCount);
    }, [files, totalFieldsConfirmed]);

    const getfileModifiedDate = (fileName, category) => {
        // const modifiedDates = Object.entries(files).filter(item => item[1].file_name === fileName)[0][1]['modified_date']
        // const modifiedDate = modifiedDates.find(item => item.hasOwnProperty(catergory))?.[catergory]
        // return modifiedDate;

        const modifiedDates = fileModifiedDateMap[fileName];
        if (!modifiedDates) return undefined; // Return undefined if no modified dates found

        const modifiedDate = modifiedDates.find(item => item.hasOwnProperty(category))?.[category];
        return modifiedDate;

    }


    const isInAcceptedDateRange = (modifiedDate) => {
        const givenDate = new Date(modifiedDate);
        const currentDate = new Date();
        const diffInMilliseconds = currentDate - givenDate;
        const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
        return diffInDays <= gtConfig.left_panel_doc_filter.numberOfDays;
    }

    const provideInitialFilterValue = () => {
        const fileTypeSet = new Set();
        const categorizedFilesTemplate = {};

        Object.entries(files).map(([fileName, fileEntry], index) => {
            const fileType = fileEntry.file_type;
            const category = fileEntry.category;
            const modifiedDates = fileEntry.modified_date;
            const extraction_modified_date = modifiedDates.find(item => item.hasOwnProperty('extraction'))?.['extraction'];

            if (extraction_modified_date && isInAcceptedDateRange(extraction_modified_date)) {
                fileTypeSet.add(fileType);

                if (!categorizedFilesTemplate[fileType]) {
                    categorizedFilesTemplate[fileType] = {};
                }
                categorizedFilesTemplate[fileType][fileEntry.file_name] = category;
            }
        });

        setFileTypes([...fileTypeSet].sort());
        setFilteredFiles(categorizedFilesTemplate);
    }

    const changedSearchTerm = (searchValue) => {
        setSearchTerm(searchValue);
        if (searchValue === '') {
            provideInitialFilterValue();
        } else {
            if (searchValue && searchValue.length < gtConfig.left_panel_doc_filter.minLengthOfSearchWord) {
                return;
            }
            // Filter files based on search term
            const filteredFiles = Object.keys(categorizedFiles).reduce(
                (filtered, fileType) => {
                    const fileMap = categorizedFiles[fileType];
                    filtered[fileType] = Object.entries(fileMap)
                        .filter(([file_name, categories]) =>
                            file_name.toLowerCase().includes(searchValue.toLowerCase())
                        )
                        .reduce((result, [file_name, categories]) => {
                            result[file_name] = categories;
                            return result;
                        }, {});
                    return filtered;
                },
                {}
            );
            const lastNFilteredDocuments = getLastNFilteredDocument(filteredFiles);
            setFilteredFiles(lastNFilteredDocuments);
        }
    }


    const getLastNFilteredDocument = (filteredFiles) => {
        return Object.keys(filteredFiles).reduce((filteredResult, type) => {
            let count = 0;

            filteredResult[type] = {};

            for (const [key, value] of Object.entries(filteredFiles[type])) {
                if (count < gtConfig.left_panel_doc_filter.numberOfDocuments) {
                    filteredResult[type][key] = value;
                    count++;
                } else {
                    break;
                }
            }

            if (count === 0) {
                delete filteredResult[type];  // Remove the type if no entries were added
            }

            return filteredResult;
        }, {});
    };


    const downloadExcel = () => {
        const workbook = XLSX.utils.book_new();
        console.log(workbook);
        // Create a worksheet for all files
        const allFilesData = Object.values({"test1": "abc"});
        const headerColumns = Object.keys({"test1": "abc"});
        const worksheet = XLSX.utils.json_to_sheet(Object.values(files), {header: headerColumns});
        XLSX.utils.book_append_sheet(workbook, worksheet, 'AllFiles');
        console.log(workbook);
        // Save the workbook as an Excel file
        const blob = XLSX.write(workbook, {bookType: 'xlsx'});
        //saveAs(excelBlob, 'all_files.xlsx');
    };

    const saveAs = (blob, fileName) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Function to handle the click event
    const handleClick = (id, fileType) => {
        // Set the selected ID and open the popup
        setSelectedId(id);
        setIsPopupOpen(true);
        setIsRightPanelVisible(false);
        const rightPanel = document.querySelector('.right-panel');
        rightPanel.style.transform = 'translateX(100%)';
        setSelectedFileType(fileType);
    };

    const onFileSelectHandler = (file, fileType, category) => {
        setSelectedFile(file)
        onFileSelect(file, fileType, category);
    }

    // Function to close the popup
    const closePopup = () => {
        setIsPopupOpen(false);
        setSelectedId(null);
        setIsRightPanelVisible(true);
        const rightPanel = document.querySelector('.right-panel');
        rightPanel.style.transform = 'translateX(0)';
    };

    const handleAccordionClick = useCallback((index) => (event, isExpanded) => {
        setExpanded(isExpanded ? index : false);

    }, []);

    const selectDocumentFromValidationIssues = (file_name, file_type) => {
        setSelectedId(file_name);
        setSearchTerm(file_name);
        changedSearchTerm(file_name);
        onFileSelectHandler(file_name, file_type, 'extraction');
    }

    return (
        <Box className="left-panel"
             sx={{
                 textAlign: 'center',
                 padding: 0,
                 backgroundColor: theme.palette.background.paper,
                 width: '16%',
                 overflow: 'auto',
             }}>

            <Box
                sx={{
                    width: '100%',
                    height: '90vh',
                }}
            >
                <Typography variant="h6" fontWeight="bold" sx={{color: theme.palette.text.primary}}> Ground Truth
                    Manager </Typography>
                <ConfirmationStatsPopup
                    totalFiles={totalFiles}
                    totalFilesConfirmed={totalFilesConfirmed}
                    totalFields={totalFields}
                    totalFieldsConfirmed={totalFieldsConfirmed}
                />

                <div style={{margin: 5, padding: 2, height: 20}}>
                    {isLoading && <LinearProgress color="primary" title='Uploading pdf document'/>}
                </div>

                <Box>
                    {searchTerm && <Typography variant='caption' sx={{color: theme.palette.text.primary}}>
                        Last <strong>{gtConfig.left_panel_doc_filter.numberOfDocuments}</strong> search
                        result</Typography>}
                    <input
                        id='searchDocument'
                        style={{
                            backgroundColor: theme.palette.background.paper,
                            color: theme.palette.text.secondary,
                            borderRadius: 4,
                            border: '1px solid',
                            padding: '2px 2px 3px 3px',
                        }}
                        type="text"
                        placeholder="Type to filter..."
                        value={searchTerm}
                        size="25"
                        onChange={(e) => changedSearchTerm(e.target.value)}
                    />
                    <Box sx={{marginTop: '2px', marginBottom: '2px'}}>
                        <LeftPanelOrderBy navOrderBy={navOrderBy} setNavOrderBy={setNavOrderBy}/>
                    </Box>
                </Box>
                <Box sx={{
                    // height: '550px',
                    transition: 'height 0.3s ease',
                    overflow: 'hidden',
                }}>
                    {fileTypes.map((fileType, index) => (
                        filteredFiles && filteredFiles[fileType] &&
                        <AccordionItems
                            key={index}
                            fileType={fileType}
                            content={filteredFiles[fileType]}
                            isExpanded={expanded === index}
                            handleAccordionClick={handleAccordionClick(index)}
                            selectedFile={selectedFile}
                            onFileSelectHandler={onFileSelectHandler}
                            getfileModifiedDate={getfileModifiedDate}
                            handleClick={handleClick}
                        />
                    ))}
                </Box>
            </Box>
            {isPopupOpen && (
                <AuditModal
                    id={selectedId}
                    fileType={selectedFileType}
                    onClose={closePopup}
                    isOpen={isPopupOpen}/>
            )}

            {isValidationIssueModalOpen && <ValidationIssueModal
                isOpen={isValidationIssueModalOpen}
                onClose={() => setValidationIssueModalOpen(false)}
                setSelectedDocument={selectDocumentFromValidationIssues}
                files={files}
            />}
            {isLockDocumentModalOpen && <LockDocumentsModal
                isOpen={isLockDocumentModalOpen}
                onClose={() => setLockDocumentModalOpen(false)}
            />}

        </Box>
    );
};

export default LeftPanel;
import pako from 'pako';

export default async function fetchDataFileUrl(url, authState, oktaAuth) {
    try {
        if (authState && authState.isAuthenticated) {
            const accessToken = oktaAuth.getAccessToken();
            const response = await getData(url, accessToken)

            if (response.ok) {
                const contentType = response.headers.get('content-type');
                if (contentType && contentType.includes('application/json')) {
                    const contentLength = response.headers.get('content-length');
                    if (contentLength === null) {
                        return {};
                    } else {
                        const responseData = await response.json();
                        const {body, headers} = responseData;

                        if (headers && headers['Content-Encoding'] === 'gzip') {
                            const compressedData = atob(body);
                            const charData = compressedData.split('').map(function (x) {
                                return x.charCodeAt(0);
                            });
                            const binData = new Uint8Array(charData);
                            const decompressedData = pako.inflate(binData, {to: 'string'});
                            return JSON.parse(decompressedData);
                        } else {
                            return responseData;
                        }
                    }
                } else {
                    console.warn(`Non-JSON content type for file: ${url}`);
                    return {};
                }
            } else if (response.status === 404) {
                console.warn(`File not found: ${url}`);
            } else {
                console.error(`Error fetching file (${url}): ${response.statusText}`);
                return {};
            }
        } else {
            console.log(authState);
            console.log(authState.isAuthenticated);
        }
    } catch
        (error) {
        console.error(`Error fetching file (${url}): ${error.message}`);
        return {};
    }
}


function getData(url, accessToken) {
    const apiUrl = `${url}`;

    return fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
    });
};


export const fetchPdfRedirectUrl = (url, authState, oktaAuth) => {
    try {
        if (authState && authState.isAuthenticated) {
            const accessToken = oktaAuth.getAccessToken();  
            return fetch(url, {
                method: 'GET', redirect: 'manual',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then(response => response.json())
            .then(json => json.body.redirect_url)
            .catch(function(err) {
                console.info(err + " url: " + url);
            });

        };
    } catch (error) {
        console.error('Error fetching PDF:', error);
    }
};

export const fetchFileUploadRedirectUrl = (url, authState, oktaAuth) => {
    try {
        if (authState && authState.isAuthenticated) {
            const accessToken = oktaAuth.getAccessToken();  
            return fetch(url, {
                method: 'GET', redirect: 'manual',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then(response => response.json())
            .then(json => json.body.redirect_url)
            .catch(function(err) {
                console.info(err + " url: " + url);
            });

        };
    } catch (error) {
        console.error('Error fetching File upload redirect url:', error);
    }
};


export const downloadPdf = async (url) => {
    try{          
        return fetch(url, {
            method: 'GET',
          }).then( response => 
              response.arrayBuffer()
          );
    } catch (error) {
        console.error(error);
    } 
}

export const saveExtraction = async (modifiedJson, authState, oktaAuth,  selectedFile, file_type, url) => {
    const modifiedJsonObject = createRequestJsonObject(modifiedJson, selectedFile, file_type);
    if (authState && authState.isAuthenticated) {
        const accessToken = oktaAuth.getAccessToken();
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(modifiedJsonObject),
        }).then(response => response.json())
            .then(data => {
              
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }
}


export async function lockUnluckSelectedDocument(authState, oktaAuth, url) {
    try {
        if (authState && authState.isAuthenticated) {
            const accessToken = oktaAuth.getAccessToken();
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            if (!response.ok) {
                console.log(`HTTP error! Status: ${response.status}`);
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            return await response.json();

        } else {
            console.log('User is not authenticated');
            throw new Error('User is not authenticated');
        }
    } catch (error) {
        console.error('Error in lockUnlockSelectedDocument:', error);
        throw error; // Re-throw the error for further handling
    }
}

export async function unlockSelectedDocuments(authState, oktaAuth, url, body) {
    try {
        if (authState && authState.isAuthenticated) {
            const accessToken = oktaAuth.getAccessToken();
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(body)
            });
            if (!response.ok) {
                console.log(`HTTP error! Status: ${response.status}`);
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            return await response.json();

        } else {
            console.log('User is not authenticated');
            throw new Error('User is not authenticated');
        }
    } catch (error) {
        console.error('Error in unlockSelectedDocuments:', error);
        throw error; // Re-throw the error for further handling
    }
}

export const getDocumentJob = async (authState, oktaAuth, url) => {
    if (authState && authState.isAuthenticated) {
        const accessToken = oktaAuth.getAccessToken();
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        });
        if (!response.ok) {
            console.log(`HTTP error! Status: ${response.status}`);
        }

        return await response.json();

    } else {
        console.log('User is not authenticated');
        throw new Error('User is not authenticated');
    }
}

export const getPathfinderJobs = async (authState, oktaAuth, url) => {
    if (authState && authState.isAuthenticated) {
        const accessToken = oktaAuth.getAccessToken();
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        });
        if (!response.ok) {
            console.log(`HTTP error! Status: ${response.status}`);
        }

        return await response.json();

    } else {
        console.log('User is not authenticated');
        throw new Error('User is not authenticated');
    }
}


export const submitExtraction = async (modifiedJson, authState, oktaAuth,  selectedFile, file_type, url) => {
    const modifiedJsonObject = createRequestJsonObject(modifiedJson, selectedFile, file_type);
    if (authState && authState.isAuthenticated) {
        const accessToken = oktaAuth.getAccessToken();
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(modifiedJsonObject),
        }).then(response => response.json()
    
        ).catch(error => {
            console.error('Error:', error);
        });
    }
}


const createRequestJsonObject = (modifiedJson, selectedFile, file_type) => {
    const modifiedJsonObject = Object.fromEntries(modifiedJson);
    modifiedJsonObject['document_name'] = selectedFile;
    modifiedJsonObject['file_type'] = file_type;

    return modifiedJsonObject;
}


